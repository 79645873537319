/* You can add global styles to this file, and also import other style files */
@import "assets/scss/main";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

.error-snackbar {
  background: #ef5350;
}

.success-snackbar {
  background: #4caf50;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}



::ng-deep {
  
    .primary-btn:first-child {
      padding: 0 16px !important;

      &:hover {
        color: #008489;
        transition: color 0.3s ease-in-out;
      }
    }

    .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
      opacity: 0;
    }

    .mat-elevation-z4,
    .mat-elevation-z5 {
      // box-shadow: none;
    }

    .mat-menu-submenu-icon {
      display: none;
    }
    .mat-menu-content:not(:empty) {
      width:255px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .mat-menu-panel {
      border-radius: 6px;
      border: 1px solid #d9dcdd;
    }

    .mat-menu-item {
     padding: 0 0 0 35px;

      &:hover {
        background-color: transparent !important;

        .mat-icon {
          color: #008489 !important;
          transition: color 0.3s ease-in-out;
        }
      }

      .mat-icon {
        position: absolute;
        top: 12px;
        left: 8px;
        transform: rotate(90deg);
      }
    }

    .cdk-overlay-connected-position-bounding-box {
      right: 0 !important;
      top: 63px !important;
    }

    #cdk-overlay-1 {
      transform: translate(-256px, 48px) !important;

      .mat-menu-item {
        padding: 0 16px !important;
      }
    }
}



@media (min-width: 1151px) {
  ::ng-deep {
    #cdk-overlay-0 {
      right: 0 !important;
      transform: translate(-90px, -6px) !important;

      .mat-menu-item {
        padding: 5px 16px !important;
      }
    }

    .mat-menu-content:not(:empty) {
      width: unset;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }

    .mat-menu-item {
       height: unset;
       line-height: unset;
      //  padding: 5px 16px !important;

      .mat-icon {
        top: 1px !important;
        left: 100px !important;
      }
    }
  }
}

@media (max-width: 450px) {
  ::ng-deep {
    #cdk-overlay-1 {
      right: 0 !important;
      transform: translate(0px, -15px) !important;
    }

    .mat-menu-item {
      padding: 0 0 0 30px !important;
      .mat-icon {
        transform: rotate(0deg);
      }
    }
  }
}