@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin boxHoverEffect {
  background: $lightgray;
  box-shadow: $hover-box-shadow;
  transform: translateY(-5px);
}

@mixin afterPseudoElement {
  position: absolute;
  bottom: -2px;
  left: 0.3rem;
  content: "";
  width: calc(100% - 0.6rem);
  height: 2px;
  background: $primary;
}

@mixin linkHoverEffect {
  @include afterPseudoElement;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease-in-out;
}