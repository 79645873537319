@import "utilities";
@import "mixins";


// For placeholder styles
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: Roboto;
}
::-moz-placeholder { /* Firefox 19+ */
  font-family: Roboto;
}
:-ms-input-placeholder { /* IE 10+ */
  font-family: Roboto;
}
:-moz-placeholder { /* Firefox 18- */
  font-family: Roboto;
}


body::-webkit-scrollbar {
  width: 0.8rem;
  background-color: darken($lightgray, 10%);
}

body::-webkit-scrollbar-thumb {
  background-color: $secondary;
  border-radius: 10px;
}

body {
  font-family: "Open Sans", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  line-height: 1.5rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  font-family: OpenSansBold;
  color: $secondary;
}

h2 {
  margin-bottom: 1.5rem;
}

h3,
h4 {
  margin-bottom: 1rem;
}

p {
  font-family: OpenSansRegular;
  color: $black;
}

li {
  display: inline-block;
  list-style-type: none;
}

a {
  font-family: OpenSansSemiBold;
  font-size: 0.9rem;
  text-decoration: none;
  display: inline-block;
  color: $secondary;
  cursor: pointer;
  user-select: none;
}

input,
textarea {
  font-family: OpenSansRegular;
}

input[type="text"],
textarea,
input[type="tel"],
input[type="email"] {
  font-size: 0.9rem;
  padding: 0.3rem 0.6rem;
  color: $black;
  background: darken($lightgray, 5%);
  caret-color: $primary;
  border: none;
  border-radius: 5px;
  &:focus {
    outline-color: $primary;
  }
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
}

fa-icon {
  font-size: 1.3rem;
  cursor: pointer;
}

.active-link {
  // box-shadow: 0 2px 0 $primary;
  // pointer-events: none;
  color: #008489;
}

.header-center {
  text-align: center;
  margin-bottom: 1rem;
}

.span-center {
  display: block;
  font-family: OpenSansRegular;
  text-align: center;
}

.container {
  width: 90%;
  max-width: 1280px;
  margin: auto;
  &.space {
    padding: $mobile-padding;
  }
}

.flex-container {
  @include flex(row, space-between, center);
  padding: $mobile-padding;
}

.img-container {
  position: relative;
  &::after {
    display: none;
    position: absolute;
    top: 5px;
    left: 7px;
    content: "";
    width: 100%;
    height: 100%;
    border: 2px solid $primary;
    border-radius: 8px;
    z-index: -1;
  }
}

.responsive-img {
  display: none;
  max-width: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.primary-btn {
  padding: 0.3rem 0.8rem;
  background: $primary;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  font-family: OpenSansRegular;
    font-size: 1rem;
  &:hover {
    background: darken($primary, 10%);
  }
  a {
    font-family: OpenSansRegular;
    font-size: 1rem;
    width: 100%;
    color: $white;
  }
}

.secondary-btn {
  background: transparent;
  a {
    font-family: OpenSansBold;
    font-size: 0.9rem;
    color: $secondary;
  }
}

.content {
  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  p {
    text-align: center;
  }
}

.arrow {
  font-size: 3rem;
  background: transparent;
}

.error-message {
  color: red;
  font-family: OpenSansRegular;
  font-size: 13px;
  text-align: left;
  margin-left: 5px;
}

@import "media";
