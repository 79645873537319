@media (max-width: 767px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  h3,
  h4 {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  h3,
  h4 {
    font-size: 1.2rem;
  }

  .img-container {
    width: 40%;
    &::after {
      display: initial;
    }
  }

  .responsive-img {
    display: initial;
  }

  .content {
    width: 55%;
    h2,
    p {
      text-align: left;
    }
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  p {
    font-size: 1rem;
  }

  .flex-container {
    padding: $medium-padding;
  }

  .container {
    &.space {
      padding: $medium-padding;
    }
  }

  .img-container {
    width: 35%;
  }

  .content {
    width: 60%;
  }
}
