// Colors

// $primary: #00a4a7;  //initial color
$primary: #008489;
$secondary: #2e3d49;
$black: #000;
$white: #fff;
$lightgray: #f7f7f8;

// Paddings
$mobile-padding: 2.5rem 0;
$medium-padding: 3rem 0;

// Box Shadow
$main-box-shadow: 5px 5px 20px darken($lightgray, 20%);
$hover-box-shadow: 5px 5px 20px 10px lighten($secondary, 50%);

// Fonts
@font-face {
  font-family: OpenSansBold;
  src: url("../fonts/OpenSans-ExtraBold.ttf");
  font-display: swap
}

@font-face {
  font-family: OpenSansSemiBold;
  src: url("../fonts/OpenSans-SemiBold.ttf");
  font-display: swap
}

@font-face {
  font-family: OpenSansRegular;
  src: url("../fonts/OpenSans-Regular.ttf");
  font-display: swap
}

@font-face {
  font-family: OpenSansLight;
  src: url("../fonts/OpenSans-Light.ttf");
  font-display: swap
}
